<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>
                                    Unscheduled candidates
                                </h4>
                                <div class="breadcrumb-sub-header">
                                <router-link to="/dashboard">Dashboard </router-link>\
                                <span>
                                    Unscheduled candidates
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" sm="6" :md="currentUser.access_type != 'score' ? 4 : 4">
                                    <v-text-field
                                        label="Enrolment key"
                                        v-model="search.exam_key"
                                        outlined
                                        dense
                                        @input="search.exam_key = $event !== null ? $event : ''"
                                        clearable
                                        v-on:keyup.enter="searchCandidate"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" :md="currentUser.access_type != 'score' ? 4 : 4" v-if="currentUser.access_type!='score'">
                                    <v-autocomplete
                                        label="SCORE"
                                        v-model="search.score_id"
                                        v-on:keyup.enter="searchCandidate"
                                        @input="search.score_id = $event !== null ? $event : ''"
                                        item-text="name"
                                        item-value="id"
                                        :items="scores"
                                        @change="handleScoreChange"
                                        outlined
                                        dense
                                        clearable>
                                    <template v-slot:template>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" :md="currentUser.access_type != 'score' ? 4 : 4">
                                    <v-text-field 
                                        label="First name"
                                        v-model="search.first_name" 
                                        outlined 
                                        dense  
                                        v-on:keyup.enter="searchCandidate"
                                        @input="search.first_name = $event !== null ? $event : ''" 
                                        clearable
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" :md="currentUser.access_type != 'score' ? 4 : 4">
                                    <v-text-field 
                                        label="Surname"
                                        v-model="search.last_name" 
                                        outlined 
                                        dense  
                                        v-on:keyup.enter="searchCandidate"
                                        @input="search.last_name = $event !== null ? $event : ''" 
                                        clearable
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="2">
                                    <v-text-field
                                        label="Class identifier"
                                        v-model="search.exam_identifier" 
                                        outlined 
                                        dense  
                                        v-on:keyup.enter="searchCandidate"
                                        @input="search.exam_identifier = $event !== null ? $event : ''" 
                                        clearable
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="2">
                                    <v-autocomplete  
                                        v-model="search.enrolment_year_id" outlined label="Year"
                                        item-text="year" 
                                        :items="enrolment_years"
                                        item-value="id"  
                                        v-on:keyup.enter="searchCandidate"
                                        dense
                                        clearable
                                    >
                                        <template v-slot:no-data>
                                            <div class="no-data-auto-complete text-center">No data available </div>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="2">
                                    <v-autocomplete
                                        @change="selectExam"
                                        v-model="selectedExam"
                                        outlined
                                        dense
                                        :items="exams"
                                        hide-selected
                                        item-text="display_text"
                                        :search-input.sync="examSearch"
                                        return-object
                                        @input="search.exam_id = $event !== null ? $event : ''" 
                                        label="Exams"
                                        placeholder="Enter exam name or subject code"
                                        :loading="isLoading"
                                        clearable
                                    >
                                    <template v-slot:label>
                                        Exam
                                    </template>
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="2">
                                    <v-autocomplete
                                        label="Category"
                                        v-model="search.category_id"
                                        item-text="name"
                                        v-on:keyup.enter="searchCandidate()"
                                        item-value="id"
                                        :items="categories"
                                        outlined
                                        clearable
                                        @change="getAllInstruments"
                                        dense>
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="2" >
                                    <v-autocomplete
                                        label="Instrument family "
                                        v-model="search.instrument_id"
                                        item-text="name"
                                        v-on:keyup.enter="searchCandidate()"
                                        item-value="id"
                                        :items="instruments"
                                        outlined
                                        clearable
                                        @change="handleInstrumentChange"
                                        :loading="instrumentLoading"
                                        dense>
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="2" >
                                    <v-autocomplete label="Syllabus "
                                        v-model="search.syllabus_id"
                                        item-text="name" v-on:keyup.enter="searchCandidate()"
                                        item-value="id"
                                        :items="syllabuses"
                                        outlined
                                        clearable
                                        :loading="isSyllabusLoading"
                                        dense>
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="2">
                                    <v-autocomplete
                                        label="Grade"
                                        v-model="search.grade_id"
                                        item-text="name"
                                        item-value="id"
                                        :items="grades"
                                        v-on:keyup.enter="searchCandidate()"
                                        outlined clearable
                                        dense
                                    >
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="2">
                                    <v-autocomplete 
                                        label="Location" 
                                        v-model="search.location_id" 
                                        item-text="name"
                                        v-on:keyup.enter="searchCandidate()" 
                                        item-value="id" 
                                        :items="locations"
                                        :loading="isLocationLoading" 
                                        outlined 
                                        clearable 
                                        dense>
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6" md="2">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        :items="exam_sessions"
                                        item-text="name"
                                        item-value="id"
                                        v-model="search.session_id"
                                        label="Session"
                                        :loading="examSessionLoading"
                                        clearable
                                        :search-input.sync="sessionSearch"
                                    >
                                    <template v-slot:label>
                                        Session
                                    </template>
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 2">
                                    <v-autocomplete
                                        v-model="search.enrolment_status"
                                        item-text="name"
                                        v-on:keyup.enter="searchCandidate()"
                                        item-value="value"
                                        :items="enrolmentStatuses"
                                        @input="search.enrolment_status = $event !== null ? $event : ''"
                                        outlined clearable
                                        dense>
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    <template v-slot:label>
                                        Enrolment status
                                    </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="2">
                                    <v-text-field
                                        label="Teacher name"
                                        v-model="search.teacher_name" 
                                        clearable
                                        outlined  
                                        v-on:keyup.enter="searchCandidate()"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="2">
                                    <v-autocomplete
                                        label="Scheduling request"
                                        v-model="search.scheduling_request_id"
                                        item-text="name"
                                        item-value="id"
                                        :loading="isSchedulingRequestLoading"
                                        :items="scheduling_requests" 
                                        v-on:keyup.enter="searchCandidate()"
                                        outlined clearable
                                        dense>
                                    <template v-slot:no-data>
                                        <div class="no-data-auto-complete text-center">No data available </div>
                                    </template>
                                    </v-autocomplete>
                                </v-col>
                                
                                <v-col cols="12" sm="6" md="2">
                                    <v-select
                                        label="Award nominated"
                                        v-model="search.nominate_for_prize"
                                        v-on:keyup.enter="searchCandidate"
                                        @input="search.nominate_for_prize = $event !== null ? $event : ''"
                                        item-text="name"
                                        item-value="id"
                                        :items="nominatedForPrizeValues"
                                        outlined
                                        dense
                                        clearable
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" sm="6" md="2">
                                    <v-text-field 
                                        label="Enroller name"
                                        v-model="search.enroler_name" 
                                        outlined 
                                        dense  
                                        v-on:keyup.enter="searchCandidate"
                                        @input="search.enroler_name = $event !== null ? $event : ''" 
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="2">
                                    <v-text-field 
                                        label="Enroller email"
                                        v-model="search.enroler_email" 
                                        outlined 
                                        dense  
                                        v-on:keyup.enter="searchCandidate"
                                        @input="search.enroler_email = $event !== null ? $event : ''" 
                                        clearable
                                    ></v-text-field>
                                </v-col>
                            
                                <v-col cols="12" sm="12" :md="currentUser.access_type == 'score' ? 6 : 2" class="text-right">
                                    <v-btn 
                                        @click.prevent="searchCandidate()"
                                        class="btn btn-primary btn-search"
                                        :loading="loading"
                                    >
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                    

                        <div class="table-responsive">
                            <v-skeleton-loader
                                type="table-thead"
                                v-if="loading"
                            >
                            </v-skeleton-loader>

                            <v-skeleton-loader
                                v-if="loading"
                                type="table-row-divider@25"
                            >
                            </v-skeleton-loader>

                            <table v-if="! loading" class="table">
                                <thead>
                                    <tr class="text-left">
                                    <th class="px-3">Surname</th>
                                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">First name</th>
                                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Middle name</th>
                                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Enrolment key</th>
                                    <th class="px-3" style="max-width: 200px; white-space: pre-wrap;">Exam name</th>
                                    <th class="px-3">Status</th>
                                    <th class="px-3">Year</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <template v-if="candidates.length != 0">
                                        <tr v-for="(item, index) in candidates" :key="index">

                                            <td class="px-3">
                                                <div class="mb-1">
                                                    {{item.last_name}}
                                                </div>
                                            </td>
                                            <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                                                <div class="mb-1">
                                                    {{item.first_name}}
                                                </div>
                                            </td>
                                            <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                                                <div class="mb-1">
                                                    {{item.middle_name}}
                                                </div>
                                            </td>
                                            <td class="px-3">
                                                <div class="mb-1">
                                                    <a href="#" @click="getCandidateSummary(item.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1"><u>{{item.exam_key}} </u>
                                                    </a>
                                                </div>
                                            </td>
                                            <td class="px-3" style="max-width: 200px; white-space: pre-wrap;">
                                                    {{item.exam_name}}
                                            </td>
                                            <td class="px-3">
                                                <div v-html="item.enrolment_status_badge"></div><br/>
                                                <div v-html="item.exam_status_badge"></div>
                                            </td>
                                            <td class="px-3">
                                                {{item.enrolment_year}}
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-else>
                                        <td class="text-center" colspan="7">
                                            No enrolments available
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <b-pagination
                                v-if="candidates.length > 0"
                                class="pull-right mt-7"
                                @input="getUnscheduledCandidates"
                                v-model="page"
                                :total-rows="total"
                                :per-page="perPage"
                                first-number
                                :disabled="loading"
                                last-number
                            ></b-pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
    import CandidateService from "@/services/candidate/CandidateService";
    import ScoreService from "@/services/score/score/ScoreService";
    import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
    import ProductService from "@/services/product/ProductService";
    import InstrumentService from "@/services/product/instrument/InstrumentService";
    import CategoryService from "@/services/product/category/CategoryService";
    import SyllabusService from "@/services/product/syllabus/SyllabusService";
    import GradeService from "@/services/product/grade/GradeService";
    import LocationService from "@/services/practical-exam/location/LocationService";
    import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
    import SchedulingRequestService from "@/services/practical-exam/scheduling-request/SchedulingRequestService";

    
    const candidate = new CandidateService();
    const score = new ScoreService();
    const enrolmentYear=new EnrolmentYearService();
    const product = new ProductService();
    const instrument = new InstrumentService();
    const category=new CategoryService();
    const syllabus = new SyllabusService();
    const grade = new GradeService();
    const location = new LocationService();
    const examSession = new ExamSession();
    const schedulingRequest = new SchedulingRequestService;

    export default {
        data(){
            return {
                loading: false,
                candidates:[],
                total: null,
                perPage: null,
                page: null,
                search:{
                    exam_key: '',
                    score_id: null,
                    first_name: '',
                    last_name: '',
                    exam_identifier: '',
                    enrolment_year_id: '',
                    exam_id: null,
                    category_id: '',
                    instrument_id: '',
                    syllabus_id: '',
                    grade_id: '',
                    location_id: '',
                    session_id: '',
                    enrolment_status: '',
                    exam_status: '',
                    teacher_name: '',
                    scheduling_request_id: '',
                    nominate_for_prize: 0,
                    enroler_name: '',
                    enroler_email: '',
                },
                scores:[],
                enrolment_years: [],
                examSearch:null,
                isLoading: false,
                sessionSearch:null,
                exams: [],
                categories: [],
                instruments: [],
                syllabuses: [],
                examSessions: [],
                grades: [],
                locations: [],
                instrumentLoading: false,
                isSyllabusLoading: false,
                isLocationLoading: false,
                examSessionLoading: false,
                isSchedulingRequestLoading: false,
                exam_sessions: [],
                enrolmentStatuses: [
                    {
                        name: 'Active',
                        value: 'active'
                    },
                    {
                        name: 'Completed',
                        value: 'completed'
                    },
                    {
                        name: 'Withdrawn',
                        value: 'withdrawn'
                    },
                    {
                        name: 'Cancelled',
                        value: 'cancelled'
                    },
                    {
                        name: 'No show',
                        value: 'no_show'
                    }
                ],
                examStatuses:[
                    {
                    name: 'Scheduled',
                    value: 'scheduled'
                    },
                    {
                    name: 'Not scheduled',
                    value: 'not_scheduled'
                    },

                    {
                    name: 'Draft scheduled',
                    value: 'draft_scheduled'
                    },

                    {
                    name: 'Pending result',
                    value: 'pending_result'
                    },
                    {
                    name: 'Assessed',
                    value: 'assessed'
                    },
                ],
                nominatedForPrizeValues: [
                    {name: 'Yes', value: 'yes'},
                    {name: 'No', value: 'no'},
                ]
            }
        },
        computed:{
            currentUser() {
                return this.$store.getters.currentUser;
            }
        },
        watch:{
            examSearch(val) {
                product
                .searchPracticalExam(val)
                .then((response) => {
                    response.data.products.map((product) => {
                    let data = product;
                    data.display_text =
                        product.name +
                        " | " +
                        product.subject_code
                    this.exams.push(data);
                    });
                })
                .catch((err) => {

                })
                .finally(() => (this.isLoading = false));
            },
            sessionSearch(val) {
                let data = {
                    name:val,
                    enrolment_year_id:this.search.enrolment_year_id
                };
                examSession
                    .search(data)
                    .then((response) => {
                    response.data.exam_sessions.map((session) => {
                        let data = session;
                        data.display_text = session.name ;
                        this.exam_sessions.push(data);
                    });
                    })
                    .catch((err) => {

                    })
                    .finally(() => (this.isLoading = false));
            },
        },
        methods:{
            handleScoreChange(){
                this.getExamSessionsByScore();
                this.getLocationsByScore();
                this.getAllSchedulingRequest();
            },
            getUnscheduledCandidates(){
                this.loading=true;
                
                candidate
                .getUnscheduledCandidates(this.search, this.page)
                .then(response => {
                    this.candidates = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                })
                .catch((err) => {
                    this.$snotify.error('Oops looks like something went wrong.');
                    this.loading = false;
                })
                .finally(()=>{
                    this.loading = false;
                });
            },
            getCandidateSummary(examKey){
                this.$router.push({
                    name: 'candidate-summary',
                    params: {examKey: examKey}
                })
            },
            searchCandidate(){
                this.page = 1;
                this.getUnscheduledCandidates();
            },
            getAllScore() {
                score
                .all()
                .then(response => {
                    this.scores = response.data.scores;
                });
            },
            getAllEnrolmentYears() {
                enrolmentYear
                    .all()
                    .then(response =>{
                        this.enrolment_years=response.data;
                        if(this.currentUser.access_type=='score') {
                            this.getUnscheduledCandidates();
                        }else{
                        if(this.year.length>0){
                            this.search.enrolment_year_id=this.year[0].id;
                            this.getUnscheduledCandidates();
                        }else{
                            this.getUnscheduledCandidates();
                        }
                        }
                    })
            },
            selectedExam(val){
                if(!val)
                    this.search.exam_id ='';
            },
            selectExam(){
                if(this.selectedExam && this.selectedExam != null){
                    this.search.exam_id = this.selectedExam.id;
                }else{
                    this.search.exam_id = "";
                }
            },
            getAllCategories() {
                category
                    .getFilterData()
                    .then(response => {
                    this.categories = response.data.categories;
                    })
                    .catch((err) => {
                    });
            },
            handleInstrumentChange(){
                this.getAllSyllabuses();
            },
            getAllInstruments() {
                this.search.instrument_id = '';
                let data = {
                    category_id:this.search.category_id
                }
                this.instrumentLoading = true;
                
                instrument
                .getFilterData(data)
                .then(response => {
                this.instruments = response.data.instruments;

                })
                .catch((err) => {
                this.instrumentLoading = false;
                })
                .finally(() => {
                    this.instrumentLoading = false;
                });

            },
            getAllSyllabuses() {
                this.search.syllabus_id = '';
                let data = {
                    instrument_id:this.search.instrument_id
                }
                this.isSyllabusLoading =true;
                syllabus
                    .getFilterData(data)
                    .then(response => {
                        this.syllabuses = response.data.syllabuses;
                    })
                    .catch((err) => {
                    })
                    .finally(() => {
                        this.isSyllabusLoading = false;
                    });
            },
            getAllGrades() {
                grade.all().then(response => {
                    this.grades = response.data.grades;
                });
            },
            getLocationsByScore() {
                this.locations = [];
                this.search.location_id = '';

                if(this.search.score_id){
                this.isLocationLoading = true;

                    location
                    .getByScore(this.search.score_id)
                    .then(response => {
                    this.locations = response.data;
                    this.isLocationLoading = false;
                    })
                    .catch((err) => {
                    this.isLocationLoading = false;
                    });
                }
            },
            getExamSessionsByScore(){
                this.examSessions = [];
                this.search.session_id = '';

                if(this.search.score_id){
                    this.examSessionLoading = true;
                    examSession
                    .getByScore(this.search.score_id)
                    .then((res) => {
                    this.examSessions = res.data;
                    this.examSessionLoading = false;
                    })
                    .catch((err) => {
                    this.examSessionLoading = false;
                    });
                }
            },
            getAllSchedulingRequest(){
                this.scheduling_requests = [];
                this.search.scheduling_request_id = '';

                if(this.search.score_id){
                    let filter = {
                    score_id: this.search.score_id
                    }
                    this.isSchedulingRequestLoading = true;
                    schedulingRequest
                        .getAllSchedulingRequest(filter)
                        .then(response => {
                        this.isSchedulingRequestLoading = false;
                        this.scheduling_requests=response.data.scheduling_requests;
                        })
                        .catch((err) => {
                        this.isSchedulingRequestLoading = false;
                        });
                }
            },
        },
        mounted(){
            this.getUnscheduledCandidates();
            this.getAllScore();
            this.getAllEnrolmentYears();
            this.getAllCategories();
            this.getAllInstruments();
            this.getAllSyllabuses();
            this.getAllGrades();
        }
    }
</script>